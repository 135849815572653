import { type ActionProps } from 'react-querybuilder';
import { Button } from 'ui-kit';

export function AddGroupAction({ path, ...props }: ActionProps) {
  if (path.length !== 0) {
    return null;
  }

  return (
    <div className="my-8 flex flex-col space-y-5 items-start">
      <p className="mx-2 font-bold">Or when</p>
      <Button
        variant="text"
        color="secondary"
        onClick={() => {
          props.handleOnClick();
        }}
      >
        Add Condition
      </Button>
    </div>
  );
}

export function AddRuleAction({ path, ...props }: ActionProps) {
  if (path.length === 0) {
    return null;
  }

  return (
    <Button
      className="!my-3 !mx-4"
      variant="text"
      color="secondary"
      onClick={(e) => {
        props.handleOnClick(e);
      }}
    >
      Add Condition
    </Button>
  );
}
