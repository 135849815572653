import {
  Button,
  Select,
  Input,
  Modal,
  WarningAmberOutlined,
  Tooltip,
  notify,
  AlertVariant,
} from 'ui-kit';

import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import {
  type FormValues,
  type VariableModalOpenPayload,
  type TransformationFormValues,
  variableModalEventChannel,
  defaultFormValue,
  VariableEventsEnum,
  defaultTransformationValues,
  defaultModalInput,
  getInitialValue,
  submitTitles,
} from '../../utils/variableModal';
import {
  Transformations,
  type TransformationsRef,
} from '../../pages/Editor/components/Transformations';
import { useTransformApiReq } from '../../pages/Editor/hooks';
import {
  EditorStore,
  type EditorStoreProps,
} from '../../pages/Editor/store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import {
  DocumentSourceEnum,
  type DocumentVariable,
  type GlobalVariable,
  QueryValueType,
  QueryValueTypeEnum,
  QueryVariable,
  SourceTypeEnum,
  SourceVariable,
  type TemplateData,
  type Variable,
  VariableBase,
  VariableString,
  VariableTypeEnum,
  ExecutionVariable,
  type EmailTriggerVariableEnum,
  EmailTriggerVariable,
  TemplateVariable,
  isSubVariable,
} from 'types-shared';
import { isAdmin } from '../../utils/env';
import sortBy from 'lodash/sortBy';
import { getVariableChipContent } from '../VariableChip';
import { clsx } from 'clsx';
import values from 'lodash/values';
import { v4 as uuid } from 'uuid';
import DocumentVariableModalContent from './DocumentVariableModalContent';
import { OtherVariablesModalContent } from './OtherVariablesModalContent';
import { SameVariableNameError } from '../../pages/Editor/components/SameVariableNameError';
import { VariableInput } from '../../pages/Editor/components/VariableTypes/VariableInput';
import omit from 'lodash/omit';
import {
  emailTriggerVariableNames,
  executionVariableTitleMapping,
} from '../../pages/Editor/utils/constants';
import { useGetSchedules } from '../../pages/ScheduleWorkflow/hooks/useScheduleWorkflows.gql';
import SubVariableModalContent from './SubVariableModalContent';
import { useFeatureFlag } from '../../utils/helper';
import { FeatureFlag } from '../../utils/constants';
import isNil from 'lodash/isNil';

export default function VariableModal() {
  const {
    updateVariable,
    addVariable,
    variables: variablesMap,
    globalVariables: globalVariablesMap,
    tableData,
    datasourceMetadata,
    workflowId,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      variables: state.variables,
      globalVariables: state.globalVariables,
      updateVariable: state.updateVariable,
      addVariable: state.addVariable,
      tableData: state.tableData,
      datasourceMetadata: state.datasourceMetadata,
      workflowId: state.workflowId,
    })),
  );

  const transformationRef = useRef<TransformationsRef>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [transformationLoaded, setTransformationLoaded] = useState(false);
  const [transformationsEnabled, setTransformationsEnabled] = useState(false);
  const [userRequestedEnabled, setUserRequestedEnabled] = useState(false);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValue);
  const [modalInput, setModalInput] = useState<VariableModalOpenPayload>(
    {} as VariableModalOpenPayload,
  );
  const [transformationFormValues, setTransformationFormValues] =
    useState<TransformationFormValues>(defaultTransformationValues);

  const variableFromVariableEnabled = useFeatureFlag(
    FeatureFlag.VariableFromVariableEnabled,
  );

  const { mutateAsync: transformApiReq, status: transformApiReqStatus } =
    useTransformApiReq(variablesMap);

  const { data: workflowSchedules = [] } = useGetSchedules(workflowId);

  const { variableId, modalAction, isCondition, updateBranchData } = modalInput;

  const isPreview = ![
    VariableEventsEnum.NEW_VARIABLE,
    VariableEventsEnum.EDIT_VARIABLE,
  ].includes(modalAction);

  const onClose = () => {
    setHasMadeChanges(false);
    setFormValues(defaultFormValue);
    setTransformationFormValues(defaultTransformationValues);
    setModalInput(defaultModalInput);
    setTransformationsEnabled(false);
    setUserRequestedEnabled(false);
    setOpen(false);
  };

  const setFormValue = (key: string, value: string | boolean) => {
    setFormValues((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const onTransformApiReq = useCallback(
    async (prompt: TemplateData, textToTransform: string) => {
      if (prompt.length > 0 && textToTransform) {
        return transformApiReq({
          data: textToTransform,
          prompt,
          model: transformationFormValues.model,
        });
      }
      return undefined;
    },
    [transformApiReq, transformationFormValues.model],
  );

  const handletransformApiReq = useCallback(
    async (prompt: TemplateData) => {
      return await onTransformApiReq(
        prompt,
        transformationFormValues.initialValue ?? '',
      );
    },
    [transformationFormValues.initialValue, onTransformApiReq],
  );

  const changeModalAction = (newModalAction: VariableEventsEnum) => {
    setModalInput({
      ...modalInput,
      modalAction: newModalAction,
    });
  };

  const onSubmit = () => {
    if (modalAction === VariableEventsEnum.EDIT_VARIABLE && variable) {
      saveOrUpdateVariable();
      onClose();
    }
    if (modalAction === VariableEventsEnum.VARIABLE_PREVIEW && variable) {
      // Use the handleSetEnabled instead of changeModalAction in order to encompass even variables that have keys and the ones that transformations already
      handleSetEnabled(transformationsEnabled);
    }
    if (modalAction === VariableEventsEnum.NEW_VARIABLE) {
      saveOrUpdateVariable();
      if (workflowSchedules.length > 0) {
        notify({
          message:
            'Workflow has active schedule. Please update the schedule with the new variable value.',
          variant: AlertVariant.WARNING,
          timeoutInMs: 6000, // 6s
        });
      }
    }
  };

  useEffect(() => {
    const unsubscribe = variableModalEventChannel.on(
      'open',
      (openPayload: VariableModalOpenPayload) => {
        setOpen(true);
        setModalInput(openPayload);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const processTitle = (title?: string) => {
    const result = title
      ?.toLowerCase()
      .replace(/_/g, ' ')
      .replace(/^\w/, (char) => char.toUpperCase());

    if (isDocumentScrape) {
      if (result === 'Variable preview') {
        return 'Download preview';
      }
      if (result === 'Edit variable') {
        return 'Edit download';
      }
    }

    return result;
  };

  const {
    variable,
    isScrape,
    isSource,
    isApiCall,
    isDocumentScrape,
    isExecutionVariable,
    isVariableFromVariable,
  } = useMemo(() => {
    const payload: {
      isScrape: boolean;
      isSource: boolean;
      variable: Variable | undefined;
      isApiCall: boolean;
      isDocumentScrape: boolean;
      isExecutionVariable: boolean;
      isVariableFromVariable: boolean;
    } = {
      isScrape: false,
      isSource: false,
      isApiCall: false,
      variable: undefined,
      isDocumentScrape: false,
      isExecutionVariable: false,
      isVariableFromVariable: false,
    };

    if (transformationFormValues.sourceId === SourceTypeEnum.Variable) {
      payload.isVariableFromVariable = true;
    }
    if (globalVariablesMap && variableId) {
      const _variable =
        globalVariablesMap[variableId] ?? variablesMap[variableId];

      setFormValues((oldFormValues) => ({
        ...oldFormValues,
        ...(_variable.name ? { variableName: _variable.name } : {}),
        variableType: _variable.type,
      }));

      payload.variable = _variable;
      payload.isScrape = _variable.type === VariableTypeEnum.Scrape;
      payload.isSource = _variable.type === VariableTypeEnum.Source;
      payload.isApiCall =
        _variable.type === VariableTypeEnum.Source &&
        _variable.data.sourceType === SourceTypeEnum.API;
      payload.isDocumentScrape =
        _variable.type === VariableTypeEnum.Document &&
        _variable.data.source === DocumentSourceEnum.Execution;
      const possibleTemplateVariable = TemplateVariable.safeParse(_variable);
      payload.isVariableFromVariable =
        possibleTemplateVariable.success &&
        Boolean(possibleTemplateVariable.data.showInVariableManager);
      payload.isExecutionVariable =
        ExecutionVariable.safeParse(_variable).success;
    }
    return payload;
  }, [
    globalVariablesMap,
    variableId,
    variablesMap,
    transformationFormValues.sourceId,
  ]);

  const { sourceVariable, showKey } = useMemo(() => {
    const _sourceVariable = Object.values(variablesMap).find(
      (v) => v.id === transformationFormValues.sourceId,
    );

    const _showKey =
      (SourceVariable.safeParse(_sourceVariable).success &&
        ![SourceTypeEnum.API, SourceTypeEnum.EmailTrigger].includes(
          SourceVariable.parse(_sourceVariable).data.sourceType,
        )) ||
      (QueryVariable.safeParse(_sourceVariable).success &&
        QueryVariable.parse(_sourceVariable).data.valueType ===
          QueryValueTypeEnum.Object);

    return {
      sourceVariable: _sourceVariable,
      showKey: _showKey && !isVariableFromVariable,
    };
  }, [isVariableFromVariable, transformationFormValues.sourceId, variablesMap]);

  const variableTypeOptions = useMemo(() => {
    const sourceVariables = Object.values(variablesMap).filter(
      (v) => v.type === VariableTypeEnum.Source,
    ) as SourceVariable[];

    const objectQueryVariables = Object.values(variablesMap).filter(
      (v) =>
        v.type === VariableTypeEnum.Query &&
        v.data.valueType === QueryValueTypeEnum.Object &&
        v.id !== variable?.id,
    ) as QueryVariable[];

    const sortedSourceVariables = sortBy(sourceVariables, (obj) => {
      if (obj.data.sourceType === SourceTypeEnum.API) return 1;
      if (obj.data.sourceType === SourceTypeEnum.Datasource) return 2;
      if (obj.data.sourceType === SourceTypeEnum.EmailTrigger) return 3;
      return 10;
    });

    if (!isAdmin) {
      return sortedSourceVariables;
    }

    return [...sortedSourceVariables, ...objectQueryVariables];
  }, [variable?.id, variablesMap]);

  const showNameError = !isPreview;

  const hasError = useMemo(() => {
    const hasVariableWithName = values({
      ...variablesMap,
      ...globalVariablesMap,
    }).some((v) => {
      if (variable && variable.name === formValues.variableName) {
        return false;
      }
      return v.name === formValues.variableName;
    });

    return hasVariableWithName && showNameError;
  }, [
    formValues.variableName,
    showNameError,
    variable,
    variablesMap,
    globalVariablesMap,
  ]);

  const disableAddVariable = useMemo(
    () =>
      !formValues.variableName ||
      (transformationsEnabled &&
        (!transformationFormValues.transformQuery ||
          isNil(transformationFormValues.previewTransform) ||
          !transformationFormValues.initialValue)) ||
      (!isScrape &&
        !isSource &&
        !transformationFormValues.valueType &&
        !isDocumentScrape &&
        !isExecutionVariable) ||
      (!isScrape &&
        !isSource &&
        variable?.type === VariableTypeEnum.Query &&
        !transformationFormValues.key.length &&
        !isDocumentScrape) ||
      hasError ||
      !hasMadeChanges ||
      (transformationsEnabled && transformApiReqStatus === 'pending'),
    [
      transformationsEnabled,
      transformationFormValues.transformQuery,
      transformationFormValues.previewTransform,
      transformationFormValues.initialValue,
      transformationFormValues.key,
      transformationFormValues.valueType,
      formValues.variableName,
      hasError,
      hasMadeChanges,
      isScrape,
      isSource,
      isDocumentScrape,
      transformApiReqStatus,
      variable?.type,
      isExecutionVariable,
    ],
  );

  const handleSetEnabled = (newEnabled: boolean) => {
    setUserRequestedEnabled(true); // Prevent overriding of setTransformationsEnabled elsewhere in the file
    setTransformationsEnabled(newEnabled);
    if (variable?.id && modalAction !== VariableEventsEnum.EDIT_VARIABLE) {
      changeModalAction(VariableEventsEnum.EDIT_VARIABLE);
    }
  };

  useEffect(() => {
    if (variable) {
      const { name = '' } = VariableBase.parse(variable);
      const transformedValue = !isNil(
        variable.dashboardData?.transformInputs?.transformedValue,
      )
        ? VariableString.parse(
            variable.dashboardData.transformInputs.transformedValue,
          )
        : undefined;
      const valueType =
        variable.type === VariableTypeEnum.Query ? variable.data.valueType : '';
      const query = variable.dashboardData?.transformInputs?.query;
      let initialValue =
        variable.type !== VariableTypeEnum.Template
          ? VariableString.parse(
              variable.dashboardData?.initialValue ||
                (isScrape
                  ? (variable as { data: { scrapedText?: string } }).data
                      .scrapedText ?? ''
                  : ''),
            )
          : '';

      if (isExecutionVariable && variable.name) {
        initialValue = getInitialValue(variable.name);
      }

      const shouldEnableTransformations = Boolean(
        !isNil(transformedValue) || (query && query.length > 0 && query[0]),
      );

      // Do not override the transformation enabled that has just been set from the handle function.
      if (!userRequestedEnabled) {
        setTransformationsEnabled(shouldEnableTransformations);
      }

      const nonSubVariableSourceId =
        variable.type === VariableTypeEnum.Query
          ? variable.data.sourceIds[0]
          : variableTypeOptions[0].id;
      const sourceId = isSubVariable(variable)
        ? SourceTypeEnum.Variable
        : nonSubVariableSourceId;

      const transformPayload = {
        name,
        key:
          variable.type === VariableTypeEnum.Query ? variable.data.query : [],
        previewTransform: transformedValue,
        transformQuery: query,
        initialValue,
        valueType,
        sourceId,
        model: variable.transformConfig?.model,
      };

      setTransformationFormValues(transformPayload);
    } else {
      setTransformationFormValues((f) => ({
        ...f,
        sourceId: variableTypeOptions[0]?.id ?? '',
      }));
    }

    if (!transformationLoaded) {
      setTransformationLoaded(true);
    }
  }, [
    transformationLoaded,
    userRequestedEnabled,
    variable,
    isScrape,
    isExecutionVariable,
    isApiCall,
    variablesMap,
    variableTypeOptions,
    transformationsEnabled,
  ]);

  const saveOrUpdateVariable = () => {
    const _extractedTransformQuery = transformationRef.current?.extractPrompt();
    const {
      key,
      sourceId,
      transformQuery: maybeTransformQuery,
      previewTransform,
      initialValue,
    } = transformationFormValues;

    // Ensure transformQuery is always an array of the correct type
    const transformQuery =
      _extractedTransformQuery?.prompt ?? maybeTransformQuery;
    const transformConfig = _extractedTransformQuery?.transformConfig;

    const { variableName: name } = formValues;

    const dashboardData: Variable['dashboardData'] = {
      transformInputs:
        transformationsEnabled &&
        transformQuery &&
        transformQuery.length > 0 &&
        !isNil(previewTransform)
          ? {
              query: transformQuery,
              transformedValue: previewTransform,
            }
          : undefined,
      initialValue: transformationsEnabled ? initialValue ?? '' : '',
    };

    if (variable && (isSource || isScrape || isExecutionVariable)) {
      updateVariable({
        ...variable,
        dashboardData,
        name: formValues.variableName,
        ...(transformConfig ? { transformConfig } : {}),
      });
      onClose();
      return;
    }

    if (isDocumentScrape) {
      updateVariable({
        ...variable,
        name: formValues.variableName,
      } as DocumentVariable);
      onClose();
      return;
    }

    const newId = uuid();
    const datasourceQueryVariable: QueryVariable = {
      id: variable ? variable.id : newId,
      name,
      type: VariableTypeEnum.Query,
      dashboardData,
      ...(transformConfig ? { transformConfig } : {}),
      data: {
        sourceIds: sourceId ? [sourceId] : [variableTypeOptions[0]?.id ?? ''],
        query: key,
        valueType: QueryValueType.safeParse(transformationFormValues.valueType)
          .success
          ? QueryValueType.parse(transformationFormValues.valueType)
          : QueryValueTypeEnum.String,
      },
    };

    if (variable) {
      updateBranchData?.(
        variable,
        modalAction === VariableEventsEnum.NEW_VARIABLE,
      );
      updateVariable(datasourceQueryVariable);
    } else {
      addVariable(datasourceQueryVariable);
      modalInput.insertVariable?.(datasourceQueryVariable);
    }

    onClose();
  };

  const emailVariableSrc = useMemo(() => {
    if (variable && QueryVariable.safeParse(variable).success) {
      const asQueryVariable = variable as QueryVariable;
      const variableSrc = variablesMap[asQueryVariable.data.sourceIds[0]];
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- will not always be true.
      if (variableSrc) {
        const isEmailVar = variableSrc.name
          ? emailTriggerVariableNames.includes(
              variableSrc.name as EmailTriggerVariableEnum,
            )
          : false;

        if (isEmailVar) {
          return variableSrc;
        }
      }
    }
    return undefined;
  }, [variable, variablesMap]);

  const variableSourceOptions = useMemo(() => {
    const variableOptions = emailVariableSrc
      ? [emailVariableSrc]
      : variableTypeOptions;
    const variableIds = variableOptions.map((v) => v.id);
    if (variableFromVariableEnabled) {
      return [...variableIds, SourceTypeEnum.Variable];
    }
    return variableIds;
  }, [emailVariableSrc, variableFromVariableEnabled, variableTypeOptions]);

  const isEmailTriggerVar = useMemo(() => {
    if (variable && EmailTriggerVariable.safeParse(variable).success) {
      return variable as EmailTriggerVariable;
    }
    return undefined;
  }, [variable]);

  return (
    <Modal
      className="flex item-center justify-items-center max-w-[65rem] py-5 max-h-[90vh] my-5"
      containerClassName="!pt-4"
      onClose={onClose}
      open={open}
      enableEventBubbling
      borderOnCloseIcon={false}
    >
      <div className="w-[45rem]">
        <div className="flex-1 flex flex-col">
          <p className="font-medium text-lg text-info-dark">
            {processTitle(modalAction)}
          </p>
        </div>

        {!isDocumentScrape &&
        Boolean(variable) &&
        modalAction === VariableEventsEnum.EDIT_VARIABLE ? (
          <div className="bg-warning-light my-6 flex flex-row gap-4 rounded-lg px-4 py-3.5">
            <WarningAmberOutlined className="text-warning" />
            <div className="flex flex-col">
              <p className="text-warning-dark text-base font-medium">
                Variable changes are global
              </p>
              <p className="text-warning-dark text-sm">
                The changes you make over existing variables will impact all the
                places where the variable is used.
              </p>
            </div>
          </div>
        ) : null}

        {variable &&
        modalAction !== VariableEventsEnum.NEW_VARIABLE &&
        isDocumentScrape ? (
          <DocumentVariableModalContent
            variable={variable}
            onCancel={onClose}
            modalAction={modalInput.modalAction}
            changeModalAction={changeModalAction}
          />
        ) : null}

        {variable &&
        isPreview &&
        !isDocumentScrape &&
        !isVariableFromVariable ? (
          <OtherVariablesModalContent
            variable={variable}
            onCancel={onClose}
            onEdit={() => {
              changeModalAction(VariableEventsEnum.EDIT_VARIABLE);
            }}
            variables={variablesMap}
            globalVariables={globalVariablesMap ?? {}}
            edgeName=""
            label=""
            isCondition={isCondition}
            updateVariable={updateVariable}
            tableData={tableData ?? null}
            datasourceMetadata={datasourceMetadata ?? null}
            variablesMap={variablesMap}
            transformationResult={transformationFormValues.previewTransform}
            transformationKey={showKey ? transformationFormValues.key : []}
            changeModalAction={changeModalAction}
          />
        ) : null}

        {!isDocumentScrape && (isVariableFromVariable || !isPreview) ? (
          <div className="flex-1">
            <Input
              classes={{ wrapper: 'flex flex-col mt-10' }}
              floatingLabel
              label="Variable name"
              onChange={(variableName: string) => {
                setHasMadeChanges(true);

                const isSourceApi =
                  SourceVariable.safeParse(sourceVariable).success &&
                  SourceVariable.parse(sourceVariable).data.sourceType ===
                    SourceTypeEnum.API;

                setFormValue('variableName', variableName);
                setTransformationFormValues({
                  ...transformationFormValues,
                  ...(isSourceApi ? { key: [variableName] } : {}),
                });
              }}
              placeholder="Name"
              rows={5}
              value={
                isExecutionVariable
                  ? executionVariableTitleMapping[formValues.variableName]
                  : formValues.variableName
              }
              disabled={isPreview || isExecutionVariable}
            />
            <SameVariableNameError
              name={formValues.variableName}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap ?? {}}
              editingVariableId={variable?.id}
            />

            {!isScrape && !isSource && !isExecutionVariable ? (
              <>
                {!isPreview || isVariableFromVariable ? (
                  <Tooltip
                    hidden={!emailVariableSrc && !isEmailTriggerVar}
                    title="This cannot be edited because this variable is derived from an email variable."
                    arrow
                    containerClassName="w-full !block"
                    placement="bottom-start"
                  >
                    <div className={clsx('mt-3 flex flex-col')}>
                      <Select
                        classes={{ select: '!py-4' }}
                        defaultValue={variableTypeOptions[0]?.id}
                        disabled={
                          variableSourceOptions.length < 2 ||
                          Boolean(emailVariableSrc) ||
                          Boolean(isEmailTriggerVar) ||
                          (isVariableFromVariable && isPreview)
                        }
                        getLabel={(opt: string) =>
                          opt === SourceTypeEnum.Variable.toString()
                            ? 'Workflow variables'
                            : getVariableChipContent(
                                variablesMap[opt] ?? globalVariablesMap?.[opt],
                              )
                        }
                        getValue={(opt: string) => opt}
                        label="Variable source"
                        labelId="template-select-variable-source"
                        onChange={(e) => {
                          setTransformationFormValues((form) => ({
                            ...form,
                            sourceId: e.target.value,
                          }));
                          setHasMadeChanges(true);
                        }}
                        options={variableSourceOptions}
                        value={transformationFormValues.sourceId}
                      />
                    </div>
                  </Tooltip>
                ) : null}

                {isVariableFromVariable ? (
                  <SubVariableModalContent
                    name={formValues.variableName}
                    variable={variable}
                    onCancel={onClose}
                    modalAction={modalInput.modalAction}
                    changeModalAction={changeModalAction}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap ?? {}}
                    onAddVariable={(v: Variable) => {
                      modalInput.insertVariable?.(v);
                    }}
                  />
                ) : null}

                {!isVariableFromVariable ? (
                  <div className={clsx('my-3 flex flex-col')}>
                    <Select
                      classes={{ select: '!py-4' }}
                      getLabel={(opt: string) => opt}
                      getValue={(opt: string) => opt}
                      label="Variable type"
                      labelId="template-select-variable-source"
                      onChange={(e) => {
                        setTransformationFormValues((form) => {
                          const updatedForm = {
                            ...form,
                            valueType: e.target.value,
                          };
                          // If the user selects QueryVariable type as object, we need to clear the transformation query and preview
                          if (e.target.value === 'Object') {
                            updatedForm.transformQuery = [];
                            updatedForm.previewTransform = '';
                          }
                          return updatedForm;
                        });
                        setHasMadeChanges(true);
                      }}
                      options={
                        isAdmin
                          ? ['String', 'Date', 'Object']
                          : ['String', 'Date']
                      }
                      value={transformationFormValues.valueType}
                      disabled={isPreview}
                    />
                  </div>
                ) : null}

                {showKey ? (
                  <div className={clsx('mt-4 flex flex-col')}>
                    <VariableInput
                      value={transformationFormValues.key}
                      onChange={(updatedQuery) => {
                        setTransformationFormValues((form) => ({
                          ...form,
                          key: updatedQuery,
                        }));
                        setHasMadeChanges(true);
                      }}
                      label="Key"
                      variablesMap={
                        variable
                          ? omit(variablesMap, [variable.id])
                          : variablesMap
                      }
                      globalVariablesMap={globalVariablesMap ?? {}}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}

        {(!isNil(transformationFormValues.previewTransform) &&
          modalAction === VariableEventsEnum.VARIABLE_PREVIEW) ||
        isDocumentScrape ||
        isVariableFromVariable ||
        !(!isApiCall && transformationFormValues.valueType !== 'Object') ||
        !transformationLoaded ? null : (
          <Transformations
            ref={transformationRef}
            isAddingAVariable={modalAction === VariableEventsEnum.NEW_VARIABLE}
            key={modalAction}
            formValues={transformationFormValues}
            setFormValues={(newVals) => {
              setTransformationFormValues(newVals);
            }}
            onTransformApiReq={handletransformApiReq}
            variablesMap={variablesMap}
            transformApiReqStatus={transformApiReqStatus}
            setEnabled={handleSetEnabled}
            enabled={transformationsEnabled}
            isDirty={hasMadeChanges}
            setIsDirty={setHasMadeChanges}
            globalVariablesMap={
              globalVariablesMap as Record<string, GlobalVariable>
            }
            variableId={variable?.id}
          />
        )}

        {!isDocumentScrape && !isVariableFromVariable ? (
          <div className="flex flex-row gap-7 mt-8 pb-5">
            <Button
              className="w-40 h-9"
              color="secondary"
              onClick={onSubmit}
              variant="contained"
              disabled={!isPreview ? disableAddVariable : false}
            >
              {submitTitles[modalAction]}
            </Button>
            <Button
              className="h-9"
              color="secondary"
              onClick={onClose}
              variant="outlined"
            >
              CANCEL
            </Button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
