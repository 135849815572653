import NavigationItem from './NavigationItem';
import {
  Profile,
  Tooltip,
  LogoFull,
  MoreMenuGridIcon,
  ChartIcon,
  MembersIcon,
  IntegrationsIcon,
  SharedVariablesIcon,
  LockIcon,
  LaptopIcon,
  KeyboardDoubleArrowLeftIcon,
  Button,
  Logo,
  AccessTimeIcon,
  AdjustIcon,
} from 'ui-kit';
import { useMemo } from 'react';
import { FeatureFlag } from '../../utils/constants';
import { isAdmin } from '../../utils/env';
import { useFeatureFlag } from '../../utils/helper';
import usePersistentState from '../../hooks/usePersistentState';
import { clsx } from 'clsx';
import useUserTeamDetails from '../../hooks/useUserTeamDetails';

export function NavigationPanel() {
  const { teamDetails } = useUserTeamDetails();
  const integrationsPageEnabled = useFeatureFlag(
    FeatureFlag.IntegrationsPage,
    false,
  );
  const apiKeysManagerPageEnabled = useFeatureFlag(FeatureFlag.ApiKeysManager);
  const extensionPageRedirectEnabled = useFeatureFlag(
    FeatureFlag.ExtensionPageRedirect,
  );
  const globalVariablesEnabled = useFeatureFlag(FeatureFlag.GlobalVariables);
  const showInsightsPage = useFeatureFlag(FeatureFlag.InsightsPage, false);
  const allExecutionsPageEnabled = useFeatureFlag(
    FeatureFlag.AllExecutionsPage,
  );
  const orchestrationPageEnabled = useFeatureFlag(
    FeatureFlag.OrchestrationPage,
  );
  const [isExpanded, setIsExpanded] = usePersistentState<boolean>(
    true,
    'isNavigationPanelExpanded',
  );

  const menuItems = useMemo(
    () => [
      {
        text: 'Workflows',
        to: '/',
        disabled: false,
        hide: false,
        icon: MoreMenuGridIcon,
      },
      {
        text: 'Executions',
        to: '/executions-list',
        disabled: false,
        tooltip: '',
        hide: !allExecutionsPageEnabled,
        icon: AccessTimeIcon,
      },
      {
        text: 'Orchestration',
        to: '/orchestration',
        disabled: false,
        hide: !orchestrationPageEnabled,
        icon: AdjustIcon,
      },
      {
        text: 'Insights',
        to: '/insights',
        disabled: false,
        hide: isAdmin || !showInsightsPage,
        tooltip: 'Contact sales to learn more!',
        icon: ChartIcon,
      },
      {
        text: 'Members',
        to: '/members',
        disabled: false,
        hide: !teamDetails?.isAdmin || isAdmin,
        icon: MembersIcon,
      },
      {
        text: 'Integrations',
        to: '/integrations',
        disabled: !integrationsPageEnabled,
        tooltip:
          'Configure external platforms and connect them with your workflows. Contact sales to learn more!',
        hide: isAdmin,
        icon: IntegrationsIcon,
      },
      {
        text: 'Shared Variables',
        to: '/global-variables',
        disabled: !globalVariablesEnabled,
        tooltip:
          'Use shared variables across your team’s workflows. Contact sales to learn more!',
        hide: false,
        icon: SharedVariablesIcon,
      },
      {
        text: 'API Keys',
        to: '/api-keys-manager',
        disabled: !apiKeysManagerPageEnabled,
        tooltip:
          'As soon as your setup is complete, this feature will be available to you!',
        hide: isAdmin,
        icon: LockIcon,
      },
    ],
    [
      orchestrationPageEnabled,
      allExecutionsPageEnabled,
      showInsightsPage,
      teamDetails?.isAdmin,
      integrationsPageEnabled,
      globalVariablesEnabled,
      apiKeysManagerPageEnabled,
    ],
  );

  const menuItemsBottom = useMemo(
    () => [
      {
        text: 'Extension',
        to: extensionPageRedirectEnabled
          ? 'https://chromewebstore.google.com/detail/sola-rpa/acmmogacpkfigcjmeahglkijobjpljgh'
          : '/extension',
        disabled: false,
        target: extensionPageRedirectEnabled ? '_blank' : undefined,
        icon: LaptopIcon,
      },
    ],
    [extensionPageRedirectEnabled],
  );

  return (
    <div
      className={clsx(
        'py-5 min-w-56 xl:min-w-72 border-r border-indigo-light flex flex-col space-y-9',
        { '!w-20 !min-w-20 group': !isExpanded },
      )}
    >
      <div className="!mt-4 flex items-center justify-between px-3">
        {isExpanded ? (
          <LogoFull className="!w-40 !h-16" />
        ) : (
          <Tooltip
            arrow
            containerClassName="mx-auto"
            title="Open sidebar"
            placement="right"
          >
            <Logo
              className="!h-16"
              onClick={() => {
                setIsExpanded(true);
              }}
            />
          </Tooltip>
        )}
        {isExpanded ? (
          <Tooltip arrow title="Close sidebar" placement="right">
            <Button
              variant="text"
              className="!min-w-max hover:!bg-sola-primary hover:!bg-opacity-10"
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            >
              <KeyboardDoubleArrowLeftIcon className="!text-color-grey" />
            </Button>
          </Tooltip>
        ) : null}
      </div>

      <div className="flex flex-col justify-between h-full">
        <ul className="px-3 flex flex-col">
          {menuItems.map((menuItem) => {
            const { to, tooltip, disabled, text, hide, icon } = menuItem;

            if (hide) {
              return null;
            }

            const navigationItem = (
              <NavigationItem
                isExpanded={isExpanded}
                className="mb-2"
                disabled={disabled}
                href={to}
                key={to}
                text={text}
                Icon={icon}
              />
            );

            return tooltip && disabled ? (
              <Tooltip arrow key={to} placement="right" title={tooltip}>
                {navigationItem}
              </Tooltip>
            ) : (
              navigationItem
            );
          })}
        </ul>

        {!isAdmin ? (
          <div>
            <ul className="px-3 py-2">
              {menuItemsBottom.map((menuItem) => (
                <NavigationItem
                  isExpanded={isExpanded}
                  className="mb-1"
                  disabled={menuItem.disabled}
                  href={menuItem.to}
                  key={menuItem.to}
                  text={menuItem.text}
                  target={menuItem.target}
                  Icon={menuItem.icon}
                />
              ))}
            </ul>

            <div className="border-t px-3 pt-4">
              <Profile teamDetails={teamDetails} isExpanded={isExpanded} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
