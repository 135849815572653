import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid/models';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import Skeleton from '@mui/material/Skeleton';
import { v4 as uuid } from 'uuid';
import { useMemo } from 'react';
import omit from 'lodash/omit';

interface Props<T extends GridValidRowModel> {
  idField?: keyof T;
  columns: GridColDef<T>[];
  length?: number;
}

export default function ListSkeleton<T extends GridValidRowModel>({
  columns,
  idField = 'workflowId',
  length = 10,
}: Props<T>) {
  const fakeData = useMemo(
    () => Array.from({ length }, () => ({ [idField]: uuid() })),
    [idField, length],
  );

  return (
    <DataGrid
      columns={columns.map((column) => ({
        ...omit(column, ['renderCell', 'valueGetter']),
        renderCell: () => (
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem' }}
            className="w-9/12"
            height={40}
          />
        ),
      }))}
      getRowId={(row) => row[idField]}
      rows={fakeData as never}
    />
  );
}
