import { clsx } from 'clsx';
import { type ComponentType } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { Tooltip } from 'ui-kit';

interface Props {
  title: string | React.ReactNode;
  value: string;
  action?: React.ReactNode;
  description?: React.ReactNode;
  link?: string;
  loading?: boolean;
  Icon?: ComponentType<{ className?: string }>;
  tooltip?: string;
  classes?: {
    container?: string;
    title?: string;
    value?: string;
    icon?: string;
  };
}

export default function MetricCard({
  title,
  value,
  action,
  description,
  link,
  Icon,
  loading,
  tooltip,
  classes,
}: Props) {
  return (
    <div
      className={clsx(
        'flex flex-col items-start text-navy-blue font-medium flex-1 px-6 py-4 rounded-lg bg-[#3174FA0A]',
        classes?.container,
      )}
    >
      <div className="flex items-center justify-between w-full">
        <p className={clsx('text-lg', classes?.title)}>{title}</p>
        {action}
      </div>
      {loading ? (
        <Skeleton
          variant="text"
          sx={{ fontSize: '2rem' }}
          className="w-full !my-3"
          height={48}
        />
      ) : (
        <>
          <div className="flex items-center space-x-3">
            <p className={clsx('text-5xl !my-3', classes?.value)}>{value}</p>
            {link && Icon ? (
              <Tooltip
                arrow
                hidden={!tooltip}
                placement="right"
                title={tooltip}
              >
                <Link to={link}>
                  <Icon
                    className={clsx(
                      '!w-5 !h-5 text-secondary-blue',
                      classes?.icon,
                    )}
                  />
                </Link>
              </Tooltip>
            ) : null}
          </div>
          {description}
        </>
      )}
    </div>
  );
}
