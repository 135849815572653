import { useMemo } from 'react';
import {
  useQueryBuilderQuery,
  useRule,
  type RuleGroupTypeAny,
  type RuleProps,
  type FullField,
  type RuleType,
} from 'react-querybuilder';
import { CancelOutlinedIcon, IconButton, Input, Select } from 'ui-kit';

interface Option {
  name: string;
  label: string;
}

export function RuleComponent(props: RuleProps) {
  const { path, rule, schema, actions } = props;
  const { fields, fieldMap } = schema;
  const { field, operator, value } = rule as RuleType<string, string, string>;
  const fieldData = fieldMap[field];
  const operators = schema.getOperators(field, {
    fieldData: fieldData as FullField,
  });
  const { validationResult, onChangeField, onChangeOperator, onChangeValue } =
    useRule(props);
  const query = useQueryBuilderQuery();

  const isInvalid = useMemo(() => {
    if (!validationResult || typeof validationResult === 'boolean') {
      return !validationResult;
    }
    return !validationResult.valid;
  }, [validationResult]);

  const errorReasons = useMemo(() => {
    if (!validationResult || typeof validationResult === 'boolean') {
      return [];
    }
    return (validationResult.reasons ?? []) as string[];
  }, [validationResult]);

  const title = useMemo(() => {
    const order = path[1];
    return `${order > 0 ? 'And' : ''} Condition ${String(order + 1)}`.trim();
  }, [path]);

  const showRemoveRuleBtn = (() => {
    // we won't show the remove button if:
    // the rule is the first rule in the first group
    // the rule is the first rule in a group with more than one rule
    if (path[0] === 0 && path[1] === 0) {
      return false;
    }
    if (path[0] !== 0) {
      const group = query.rules[path[0]] as RuleGroupTypeAny | undefined;
      return path[1] > 0 || group?.rules.length === 1;
    }
    return true;
  })();

  const removeRule = () => {
    // if the rule is the first rule in the group, remove the group
    if (path[1] === 0) {
      actions.onGroupRemove([path[0]]);
    } else {
      actions.onRuleRemove(path);
    }
  };

  return (
    <div className="rule flex flex-col items-start space-y-8" {...props}>
      <div className="flex items-center justify-between w-full">
        <span className="font-bold text-sm">{title}</span>
        {showRemoveRuleBtn ? (
          <IconButton color="primary" onClick={removeRule}>
            <CancelOutlinedIcon className="!w-5 !h-5" />
          </IconButton>
        ) : null}
      </div>
      <div className="flex w-full items-start space-x-4 !ml-0">
        <Select<Option>
          label="Field"
          placeholder="Select field"
          value={field as unknown as Option}
          onChange={(event) => {
            onChangeField(event.target.value as string);
          }}
          options={fields as Option[]}
          getLabel={(opt) => opt.label}
          getValue={(opt) => opt.name}
          className="flex-1"
        />
        <Select<Option>
          label="Operator"
          placeholder="Select operator"
          value={operator as unknown as Option}
          onChange={(event) => {
            onChangeOperator(event.target.value as string);
          }}
          options={operators as Option[]}
          getLabel={(opt) => opt.label}
          getValue={(opt) => opt.name}
          className="flex-1"
        />
        <Input
          classes={{ wrapper: 'flex-1', input: '!mb-1' }}
          label="Value"
          floatingLabel
          value={value}
          onChange={(newValue) => {
            onChangeValue(newValue);
          }}
          error={isInvalid}
          errorText={errorReasons.join(', ')}
        />
      </div>
    </div>
  );
}
