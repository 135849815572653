import { type SimplifiedField } from 'query-builder-utils';
import type {
  QueryValidator,
  RuleGroupType,
  ValidationMap,
} from 'react-querybuilder';
import { QueryBuilder as ReactQueryBuilder } from 'react-querybuilder';
import { RuleComponent } from './Rule';
import { AddGroupAction, AddRuleAction } from './Actions';
import { getRandomRuleGroup } from './utils';
import './style.css';

interface Props {
  query: RuleGroupType;
  fields: SimplifiedField[];
  onQueryChange: (query: RuleGroupType) => void;
  showValidationErrors?: boolean;
}

export default function QueryBuilder({
  query,
  fields,
  onQueryChange,
  showValidationErrors,
}: Props) {
  const handleAddGroup = () => {
    const group = getRandomRuleGroup(fields);
    onQueryChange({
      ...query,
      rules: [...query.rules, group],
    });
  };

  const validator: QueryValidator = (q): ValidationMap => {
    if (showValidationErrors) {
      return {};
    }
    // if showValidationErrors, we'll forcefully mark all rules as valid
    const validationMap: ValidationMap = {};
    q.rules.forEach((item) => {
      const subRule = item as RuleGroupType;
      subRule.rules.forEach((rule) => {
        const { id } = rule as { id: string };
        validationMap[id] = true;
      });
    });
    return validationMap;
  };

  return (
    <ReactQueryBuilder
      query={query}
      fields={fields}
      onQueryChange={onQueryChange}
      validator={validator}
      controlElements={{
        rule: RuleComponent,
        addRuleAction: AddRuleAction,
        // eslint-disable-next-line react/no-unstable-nested-components
        addGroupAction: (props) => (
          <AddGroupAction {...props} handleOnClick={handleAddGroup} />
        ),
      }}
      combinators={[
        { name: 'and', label: 'AND' },
        { name: 'or', label: 'OR' },
      ]}
    />
  );
}
