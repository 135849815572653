import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { handleException } from 'sentry-browser-shared';
import { VariableString } from 'types-shared';
import {
  parseTemplateString,
  TemplateData,
  type TemplateVariable,
  VariableRef,
  VariableTypeEnum,
  type Variable,
  type VariableMap,
} from 'types-shared/workflowTypes';
import {
  AutoFixInactiveIcon,
  Button,
  CheckCircleIcon,
  GrayedOutInput,
  IconButton,
  InfoOutlined,
  Input,
  SendIcon,
  Spinner,
  Tooltip,
} from 'ui-kit';
import { v4 as uuid } from 'uuid';
import { useShallow } from 'zustand/react/shallow';
import {
  VariableInput,
  type VariableInputRef,
} from '../../pages/Editor/components/VariableTypes/VariableInput';
import { useSubVariableTransform } from '../../pages/Editor/hooks';
import {
  EditorStore,
  type EditorStoreProps,
} from '../../pages/Editor/store/EditorState';
import {
  submitTitles,
  VariableEventsEnum,
  type BaseVariablePreviewProps,
} from '../../utils/variableModal';
import { VariableChip } from '../VariableChip';
import { isAdmin } from '../../utils/env';
import { ModelSelect } from '../ModelSelect';

type Props = Omit<BaseVariablePreviewProps, 'variable'> & {
  variable?: Variable;
  variablesMap: VariableMap;
  globalVariablesMap: VariableMap;
  name: string;
  onAddVariable: (variable: Variable) => void;
};

const query =
  'Generate a value from this statement without returning any explanation';

export default function SubVariableModalContent({
  variable,
  variablesMap,
  globalVariablesMap,
  modalAction,
  onCancel,
  changeModalAction,
  name,
  onAddVariable,
}: Props) {
  const { addVariable, updateVariable } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      addVariable: state.addVariable,
      updateVariable: state.updateVariable,
    })),
  );
  const isPreview = modalAction === VariableEventsEnum.VARIABLE_PREVIEW;
  const inputVariableInputRef = useRef<VariableInputRef>();
  const isDirtyRef = useRef(false);
  const [localDirty, setLocalDirty] = useState(false);
  const [reasoning, setReasoning] = useState<string | null>(null);
  const prevFormValues = useRef<TemplateData>([]);
  const [formValues, setFormValues] = useState<{
    internalVariableValues: Record<string, string>;
    transformedValue?: string;
    model?: string;
  }>({
    internalVariableValues: {},
    transformedValue: undefined,
    model: '',
  });

  const transformedVariableMap = useMemo(() => {
    const finalVariableMap = {
      ...globalVariablesMap,
      ...variablesMap,
    };

    Object.entries(formValues.internalVariableValues).forEach(
      ([key, value]) => {
        const _variable = finalVariableMap[key];
        if (!isNil(_variable)) {
          finalVariableMap[key] = {
            ..._variable,
            dashboardData: {
              ..._variable.dashboardData,
              transformInputs: {
                query: _variable.dashboardData?.transformInputs?.query ?? [],
                transformedValue: value,
              },
              initialValue: _variable.dashboardData?.initialValue ?? {},
            },
          };
        }
      },
    );
    return finalVariableMap;
  }, [formValues.internalVariableValues, globalVariablesMap, variablesMap]);

  const { mutateAsync: transformApiReq, status: transformApiReqStatus } =
    useSubVariableTransform(transformedVariableMap);

  const isLoading = transformApiReqStatus === 'pending';
  const isSuccess = transformApiReqStatus === 'success';
  const isError = transformApiReqStatus === 'error';

  const disableAddVariable = useMemo(() => {
    if (
      ![
        VariableEventsEnum.NEW_VARIABLE,
        VariableEventsEnum.EDIT_VARIABLE,
      ].includes(modalAction)
    ) {
      return false;
    }
    const hasInvalidInitialValue = Object.values(
      formValues.internalVariableValues,
    ).some((value) => isEmpty(value));
    return (
      hasInvalidInitialValue || isEmpty(formValues.transformedValue) || !name
    );
  }, [formValues, modalAction, name]);

  const saveOrUpdateVariable = () => {
    const stringifiedData = parseTemplateString({
      data: prevFormValues.current,
      variableMap: transformedVariableMap,
      handleException,
    });
    const transformConfig = formValues.model
      ? { model: formValues.model }
      : undefined;
    if (!variable) {
      const newId = uuid();
      const newVariable: Variable = {
        id: newId,
        name,
        showInVariableManager: true,
        type: VariableTypeEnum.Template,
        data: prevFormValues.current,
        dashboardData: {
          transformInputs: {
            query: [query],
            transformedValue: formValues.transformedValue ?? '',
          },
          initialValue: VariableString.parse(stringifiedData),
        },
        transformConfig,
      };
      addVariable(newVariable);
      onAddVariable(newVariable);
    } else {
      const { dashboardData } = variable;
      updateVariable({
        ...variable,
        name,
        data: prevFormValues.current,
        dashboardData: {
          ...dashboardData,
          transformInputs: {
            query: [query],
            transformedValue:
              formValues.transformedValue ??
              dashboardData?.transformInputs?.transformedValue ??
              '',
          },
          initialValue: VariableString.parse(stringifiedData),
        },
        transformConfig,
      } as TemplateVariable);
    }
    onCancel();
  };

  const onSubmit = () => {
    if (isPreview) {
      changeModalAction(VariableEventsEnum.EDIT_VARIABLE);
    } else {
      saveOrUpdateVariable();
    }
  };

  const onTransformApiReq = useCallback(async () => {
    setLocalDirty(false);
    const value = await transformApiReq({
      data: prevFormValues.current,
      prompt: query,
      model: formValues.model,
    });
    setReasoning(value?.reasoning ?? null);
    setFormValues((form) => ({
      ...form,
      transformedValue: value?.processedData ?? undefined,
    }));
  }, [formValues.model, transformApiReq]);

  const extractVariablesFromPrompt = (prompt: TemplateData) => {
    const variables = prompt.filter(
      (item) => VariableRef.safeParse(item).success,
    ) as VariableRef[];
    setFormValues((form) => {
      const newVariablesMap = variables.reduce(
        (acc: Record<string, string>, _variable: VariableRef) => {
          acc[_variable.id] = form.internalVariableValues[_variable.id] ?? '';
          return acc;
        },
        {},
      );
      return {
        ...form,
        internalVariableValues: newVariablesMap,
      };
    });
  };

  const iconButtonContent = useMemo(() => {
    if (isLoading) {
      return <Spinner size={16} />;
    }

    if (isSuccess && !isError && !localDirty) {
      return <CheckCircleIcon className="text-transparent" />;
    }

    if (isError && !localDirty) {
      return <InfoOutlined className="text-error" />;
    }

    if (!isSuccess || localDirty) {
      return <SendIcon className="text-white" />;
    }
  }, [isLoading, isSuccess, isError, localDirty]);

  useEffect(() => {
    if (variable?.dashboardData) {
      const { dashboardData, data, transformConfig } = variable;
      const parsedData = TemplateData.safeParse(data);
      if (parsedData.success) {
        prevFormValues.current = parsedData.data;
      }
      setFormValues({
        internalVariableValues: {},
        transformedValue: dashboardData.transformInputs
          ?.transformedValue as string,
        model: transformConfig?.model ?? '',
      });
    }
  }, [variable]);

  return (
    <div className="space-y-8 mt-8">
      <h2 className="text-sm font-medium text-navy-blue">
        <Tooltip
          containerClassName="!mr-4 !inline"
          title={reasoning || 'No reasoning available'}
          placement="right"
          arrow
        >
          <AutoFixInactiveIcon
            fontSize="small"
            className={clsx('!text-navy-blue', {
              'cursor-help': Boolean(reasoning),
            })}
          />
        </Tooltip>
        Combine one or more workflow variables with GPT instructions to create a
        new variable
      </h2>
      <p className="text-sm text-color-grey !mt-2">
        Automatically create a new variable based on existing workflow variables
        with a natural language prompt.
      </p>

      <div
        className={clsx('relative !mt-5', {
          'pointer-events-none bg-gray-100 opacity-80': isPreview,
        })}
      >
        <VariableInput
          ref={inputVariableInputRef}
          showPlusButton
          allowAddVariable={false}
          disabled={isPreview}
          disabledAddVariableTooltip="Variables cannot be added on the transformation prompt. Create them on the input instead."
          label="Prompt"
          value={prevFormValues.current}
          onChange={(newTransformQuery) => {
            if (!isDirtyRef.current && isEmpty(newTransformQuery)) {
              isDirtyRef.current = true;
              return;
            }

            setLocalDirty(true);
            prevFormValues.current = newTransformQuery;
            extractVariablesFromPrompt(newTransformQuery);
          }}
          className={clsx('min-h-40')}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          placeholder="Add variables and write instructions"
        />

        <IconButton
          className="!absolute bottom-1 right-1"
          disabled={!localDirty && (isSuccess || isError || isLoading)}
          onClick={onTransformApiReq}
        >
          {iconButtonContent}
        </IconButton>
      </div>

      <div className="flex flex-col space-y-1">
        <h2 className="text-sm font-medium text-navy-blue">
          Variable value examples
        </h2>
        <p className="text-sm text-color-grey !mt-1">
          Add value examples to the variables inside the prompt to preview the
          new variable value result.
        </p>
      </div>

      <div className="flex flex-col space-y-6 !mt-4">
        {Object.entries(formValues.internalVariableValues).map(
          ([key, value]) => (
            <div key={key} className="flex items-center space-x-7">
              <GrayedOutInput
                height={60}
                className="flex-1 mt-0.5"
                labelClassName="!mb-0"
                label="Variable"
                html={
                  <VariableChip
                    variableId={key}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                  />
                }
              />
              {isPreview ? (
                <GrayedOutInput
                  className="flex-1"
                  label="Value example"
                  value={value}
                />
              ) : (
                <Input
                  classes={{
                    wrapper: 'flex-1 mb-0.5',
                  }}
                  floatingLabel
                  disabled={isPreview}
                  label="Value example"
                  value={value}
                  onChange={(val) => {
                    setLocalDirty(true);
                    setFormValues((form) => ({
                      ...form,
                      internalVariableValues: {
                        ...form.internalVariableValues,
                        [key]: val,
                      },
                    }));
                  }}
                />
              )}
            </div>
          ),
        )}
        {isEmpty(formValues.internalVariableValues) ? (
          <div className="flex items-center space-x-7">
            <GrayedOutInput
              className="flex-1"
              label="Variable"
              value="No variable added"
            />
            <GrayedOutInput
              className="flex-1"
              label="Value example"
              value="No variable added"
            />
          </div>
        ) : null}

        {isAdmin ? (
          <ModelSelect
            value={formValues.model || ''}
            onChange={(newModel) => {
              setLocalDirty(true);
              setFormValues((prevValues) => ({
                ...prevValues,
                model: newModel,
              }));
            }}
          />
        ) : null}
      </div>

      <div className="flex flex-col space-y-1">
        <h2 className="text-sm font-medium text-navy-blue">Value result</h2>
        <p className="text-sm text-color-grey !mt-1">
          AI results may not always be accurate. Please review and verify the
          outputs for critical use cases.
        </p>
      </div>

      <div
        className={clsx('!mt-4 p-[1px] !rounded bg-zinc-100', {
          '!bg-gradient-to-r from-primary-blue to-primary-purple !p-0.5 font-mono':
            formValues.transformedValue,
        })}
      >
        <div className="!rounded-sm p-3 min-h-[2.75rem] break-words bg-zinc-100 whitespace-pre-wrap">
          {formValues.transformedValue ? (
            formValues.transformedValue
          ) : (
            <span className="text-neutral-400 font-normal">
              Transformation Result
            </span>
          )}
        </div>
      </div>

      <div className="flex flex-row gap-7 mt-8 pb-5">
        <Button
          className="w-40 h-9"
          color="secondary"
          onClick={onSubmit}
          variant="contained"
          disabled={disableAddVariable}
        >
          {submitTitles[modalAction]}
        </Button>
        <Button
          className="h-9"
          color="secondary"
          onClick={onCancel}
          variant="outlined"
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}
