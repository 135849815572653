import { type SimplifiedField } from 'query-builder-utils';
import { v4 as uuid } from 'uuid';

export const getRandomRule = (fields: SimplifiedField[]) => {
  if (!fields.length) {
    throw new Error('No fields found');
  }

  const field = fields[0];
  if (!field.operators.length) {
    throw new Error('No operators found');
  }

  const operator = field.operators[0];

  return {
    id: uuid(),
    field: field.name,
    operator: operator.name,
    value: '',
  };
};

export const getRandomRuleGroup = (fields: SimplifiedField[]) => {
  return {
    id: uuid(),
    combinator: 'and',
    rules: [getRandomRule(fields)],
  };
};
