import { clsx } from 'clsx';
import { Button } from 'ui-kit';

export enum EmptyStateEnum {
  WORKFLOW = 'workflow',
  EXECUTION = 'execution',
  OUTPUTS = 'outputs',
  DONUT_GRAPH = 'donut-graph',
  BAR_GRAPH = 'bar-graph',
}

const EmptyStateComponentMapping = {
  [EmptyStateEnum.WORKFLOW]: (
    <img
      className="!w-96 !h-60 object-contain"
      src="/empty_workflow.svg"
      alt="Empty Workflow"
    />
  ),
  [EmptyStateEnum.EXECUTION]: (
    <img
      className="!w-96 !h-60 object-contain"
      src="/empty_execution.svg"
      alt="Empty Execution"
    />
  ),
  [EmptyStateEnum.OUTPUTS]: (
    <img
      className="!w-96 !h-60 object-contain"
      src="/empty_outputs.svg"
      alt="Empty Outputs"
    />
  ),
  [EmptyStateEnum.DONUT_GRAPH]: (
    <img
      className="!w-96 !h-40 mb-5 object-contain"
      src="/empty_donut_graph.svg"
      alt="Empty Outputs"
    />
  ),
  [EmptyStateEnum.BAR_GRAPH]: (
    <img
      className="!w-96 !h-40 mb-5 object-contain"
      src="/empty_bar_graph.svg"
      alt="Empty Outputs"
    />
  ),
};

interface EmptyStatePlaceholderProps {
  className?: string;
  type: EmptyStateEnum;
  buttonProps?: {
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
  };
  title?: string;
  description?: string;
  buttonText?: string;
}

export default function EmptyStatePlaceholder({
  className,
  type,
  buttonProps,
  title = '',
  description = '',
  buttonText = '',
}: EmptyStatePlaceholderProps) {
  const Component = EmptyStateComponentMapping[type];

  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center p-8 text-center',
        className,
      )}
    >
      <div>{Component}</div>
      <div className="flex flex-col items-center justify-center max-w-[600px]">
        {title ? (
          <h3 className="text-xl text-info-dark font-semibold mb-2">{title}</h3>
        ) : null}
        {description ? <p className="text-gray-600">{description}</p> : null}
        {buttonText && buttonProps ? (
          <Button
            className="!mt-6"
            color="secondary"
            variant="contained"
            {...buttonProps}
          >
            {buttonText}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
